// pages/LoginPage.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '../organisms/LoginForm';
import { AuthTemplate } from '../templates/AuthTemplate';
import { useAuthStore } from '../stores/authStore';

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/character');
    }
  }, [isAuthenticated, navigate]);
  
  return (
    <AuthTemplate>
      <LoginForm />
    </AuthTemplate>
  );
};