// organisms/RoundHistory.tsx
import React from 'react';
import { Typography, List, Box } from '@mui/material';
import { RoundHistoryItem } from '../molecules/RoundHistoryItem';
import { useBattleStore } from '../stores/battleStore';
import { useAuthStore } from '../stores/authStore';
import HistoryIcon from '@mui/icons-material/History';

export const RoundHistory: React.FC = () => {
  const { currentBattle } = useBattleStore();
  const { user } = useAuthStore();
  
  if (!currentBattle || !user) return null;
  
  const rounds = currentBattle.rounds;
  
  return (
    <Box>
      {rounds.length > 0 ? (
        <List sx={{ width: '100%', p: 0 }}>
          {rounds.slice().reverse().map((round, index) => (
            <RoundHistoryItem
              key={rounds.length - index}
              round={round}
              roundNumber={rounds.length - index}
              battleOpponents={currentBattle.opponents}
              currentUserId={user.id}
            />
          ))}
        </List>
      ) : (
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            py: 6,
            opacity: 0.6
          }}
        >
          <HistoryIcon sx={{ fontSize: '3rem', mb: 2, color: 'text.secondary' }} />
          <Typography align="center" color="text.secondary">
            Історія раундів буде доступна після вашого першого ходу
          </Typography>
        </Box>
      )}
    </Box>
  );
};