import React, { useState } from 'react';
import { Grid, Typography, Box, Divider } from '@mui/material';
import { ActionButton } from '../molecules/ActionButton';
import { Button } from '../atoms/Button';
import { useBattleStore } from '../stores/battleStore';
import { useSocket } from '../hooks/useSocket';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import ShieldIcon from '@mui/icons-material/Shield';
import SendIcon from '@mui/icons-material/Send';

export const BattleActions: React.FC = () => {
  const { currentBattle, inMove } = useBattleStore();
  const { moveBattle } = useSocket();
  
  const [selectedHit, setSelectedHit] = useState<string | null>(null);
  const [selectedBlock, setSelectedBlock] = useState<string | null>(null);
  
  if (!currentBattle) return null;
  
  const handleActionSelect = (action: string) => {
    if (action.startsWith('HIT_')) {
      setSelectedHit(selectedHit === action ? null : action);
    } else if (action.startsWith('BLOCK_')) {
      setSelectedBlock(selectedBlock === action ? null : action);
    }
  };
  
  const handleSubmit = () => {
    if (!selectedHit || !selectedBlock) {
      alert('Виберіть 1 удар та 1 блок');
      return;
    }
    
    const actions = [selectedHit, selectedBlock];
    
    useBattleStore.getState().moveBattleRequest({ 
      battleId: currentBattle.id, 
      actions 
    });
    
    moveBattle({
      battleId: currentBattle.id,
      actions
    });
    
    setSelectedHit(null);
    setSelectedBlock(null);
  };
  
  return (
    <Box>
      <Typography variant="subtitle1" align="center" gutterBottom>
        Виберіть 1 удар та 1 блок для вашого ходу
      </Typography>
      
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <SportsMmaIcon color="error" sx={{ mr: 1 }} />
            <Typography variant="subtitle1" fontWeight="medium">Удари:</Typography>
          </Box>
          <Divider />
        </Grid>
        
        <Grid item xs={12} sm={4}>
          <ActionButton
            actionType="hit"
            target="head"
            selected={selectedHit === 'HIT_HEAD'}
            onClick={() => handleActionSelect('HIT_HEAD')}
            fullWidth
            disabled={inMove}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ActionButton
            actionType="hit"
            target="body"
            selected={selectedHit === 'HIT_BODY'}
            onClick={() => handleActionSelect('HIT_BODY')}
            fullWidth
            disabled={inMove}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ActionButton
            actionType="hit"
            target="legs"
            selected={selectedHit === 'HIT_LEGS'}
            onClick={() => handleActionSelect('HIT_LEGS')}
            fullWidth
            disabled={inMove}
          />
        </Grid>
        
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <ShieldIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="subtitle1" fontWeight="medium">Блоки:</Typography>
          </Box>
          <Divider />
        </Grid>
        
        <Grid item xs={12} sm={4}>
          <ActionButton
            actionType="block"
            target="head"
            selected={selectedBlock === 'BLOCK_HEAD'}
            onClick={() => handleActionSelect('BLOCK_HEAD')}
            fullWidth
            disabled={inMove}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ActionButton
            actionType="block"
            target="body"
            selected={selectedBlock === 'BLOCK_BODY'}
            onClick={() => handleActionSelect('BLOCK_BODY')}
            fullWidth
            disabled={inMove}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ActionButton
            actionType="block"
            target="legs"
            selected={selectedBlock === 'BLOCK_LEGS'}
            onClick={() => handleActionSelect('BLOCK_LEGS')}
            fullWidth
            disabled={inMove}
          />
        </Grid>
      </Grid>
      
      <Box sx={{ mt: 3, textAlign: 'center' }}>
        <Button 
          variant="contained" 
          color="success" 
          onClick={handleSubmit} 
          disabled={inMove || !selectedHit || !selectedBlock}
          sx={{ 
            px: 4, 
            py: 1.5, 
            fontSize: '1.1rem',
            fontWeight: 'bold',
            minWidth: '200px',
            background: inMove ? 'rgba(76, 175, 80, 0.5)' : 'success.main'
          }}
          startIcon={inMove ? null : <SendIcon />}
        >
          {inMove ? 'Очікуємо хід суперника...' : 'Відправити хід'}
        </Button>
      </Box>
    </Box>
  );
};