// pages/TowerPage.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Paper, 
  Typography, 
  Button, 
  Card, 
  CardContent, 
  Grid, 
  Stepper, 
  Step, 
  StepLabel, 
  StepContent,
  Divider, 
  CircularProgress,
  Avatar,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { GameTemplate } from '../templates/GameTemplate';
import { useTowerStore } from '../stores/towerStore';
import { useCharacterStore } from '../stores/characterStore';
import { useSocket } from '../hooks/useSocket';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import { Tower, TowerBattle } from '../types/tower';

export const TowerPage: React.FC = () => {
  const navigate = useNavigate();
  const { activeTower, fetchActiveTower } = useTowerStore();
  const { characters, selectedCharacter, fetchCharacters } = useCharacterStore();
  const { advanceToNextFloor, retireTower } = useSocket();
  const [loading, setLoading] = useState(false);
  const [showRetireDialog, setShowRetireDialog] = useState(false);
  
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        // Завантажуємо активну башту
        await fetchActiveTower();
        // Завантажуємо персонажів (якщо потрібно)
        if (characters.length === 0) {
          await fetchCharacters();
        }
      } catch (error) {
        console.error("Помилка завантаження даних:", error);
      } finally {
        setLoading(false);
      }
    };
    
    loadData();
  }, [fetchActiveTower, fetchCharacters, characters.length]);
  
  // Якщо немає активної башти - переходимо на сторінку вибору персонажа
  useEffect(() => {
    if (!loading && !activeTower) {
      navigate('/character');
    }
  }, [activeTower, loading, navigate]);
  
  // Знаходимо персонажа, який проходить башту
  const towerCharacter = activeTower 
    ? characters.find(char => char.id === activeTower.characterId) 
    : null;
  
  const handleAdvanceToNextFloor = () => {
    if (!activeTower) return;
    
    advanceToNextFloor({ towerId: activeTower.id });
  };
  
  const handleRetireTower = () => {
    setShowRetireDialog(true);
  };
  
  const confirmRetire = () => {
    if (!activeTower) return;
    
    retireTower({ towerId: activeTower.id });
    setShowRetireDialog(false);
  };
  
  const getBattleStatusColor = (battle?: TowerBattle) => {
    if (!battle) return 'default';
    
    if (!battle.completed) return 'info';
    
    switch (battle.result) {
      case 'win': return 'success';
      case 'loss': return 'error';
      case 'draw': return 'warning';
      default: return 'default';
    }
  };
  
  const getBattleStatusIcon = (battle?: TowerBattle) => {
    if (!battle) return null;
    
    if (!battle.completed) return <SportsKabaddiIcon />;
    
    switch (battle.result) {
      case 'win': return <CheckCircleIcon color="success" />;
      case 'loss': return <CancelIcon color="error" />;
      case 'draw': return <ErrorIcon color="warning" />;
      default: return null;
    }
  };
  
  const getBattleStatusText = (battle?: TowerBattle) => {
    if (!battle) return 'Не розпочато';
    
    if (!battle.completed) return 'В процесі';
    
    switch (battle.result) {
      case 'win': return 'Перемога';
      case 'loss': return 'Поразка';
      case 'draw': return 'Нічия';
      default: return 'Невідомо';
    }
  };
  
  // Обробка станів завантаження та помилок
  if (loading) {
    return (
      <GameTemplate title="Завантаження...">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress size={60} />
        </Box>
      </GameTemplate>
    );
  }
  
  if (!activeTower || !towerCharacter) {
    return (
      <GameTemplate title="Ой! Щось пішло не так">
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="h5" gutterBottom>
            Не вдалося завантажити інформацію про башту
          </Typography>
          <Button 
            variant="contained" 
            onClick={() => navigate('/character')} 
            sx={{ mt: 2 }}
          >
            Повернутися до вибору персонажа
          </Button>
        </Box>
      </GameTemplate>
    );
  }
  
  const isCompletedOrFailed = activeTower.status === 'completed' || activeTower.status === 'failed';
  const canAdvance = activeTower.status === 'active' && 
    activeTower.currentFloor < activeTower.maxFloors &&
    activeTower.battles.some(battle => 
      battle.floor === activeTower.currentFloor && 
      battle.completed && 
      battle.result === 'win'
    );
  
  return (
    <GameTemplate title="Башта Випробувань">
      <Box sx={{ mb: 4 }}>
        <Card sx={{ 
          mb: 4,
          p: 2,
          background: 'linear-gradient(135deg, rgba(58, 74, 61, 0.8), rgba(42, 58, 45, 0.8))',
          boxShadow: 4
        }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={2} sx={{ textAlign: 'center' }}>
              <Avatar 
                src={`/images/characters/${towerCharacter.code}.png`}
                sx={{
                  width: 100,
                  height: 100,
                  mx: 'auto',
                  border: '3px solid rgba(232, 226, 213, 0.7)',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)'
                }}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography variant="h5" color="white" fontWeight="bold">
                {towerCharacter.name}
              </Typography>
              <Typography variant="body1" color="white" sx={{ opacity: 0.9, mb: 1 }}>
                Рівень: {towerCharacter.level} | Поточний поверх: {activeTower.currentFloor} / {activeTower.maxFloors}
              </Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Chip 
                  label={activeTower.status === 'active' ? 'Активна' : 
                        activeTower.status === 'completed' ? 'Завершена' : 'Провалена'} 
                  color={activeTower.status === 'active' ? 'info' : 
                        activeTower.status === 'completed' ? 'success' : 'error'}
                  sx={{ mr: 2 }}
                />
                <Typography variant="body2" color="white" sx={{ opacity: 0.8 }}>
                  Розпочато: {new Date(activeTower.createdAt).toLocaleString()}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ textAlign: 'center' }}>
              {canAdvance && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ArrowUpwardIcon />}
                  onClick={handleAdvanceToNextFloor}
                  sx={{ 
                    py: 1.5,
                    px: 3,
                    fontWeight: 'bold'
                  }}
                >
                  На наступний поверх
                </Button>
              )}
              
              {isCompletedOrFailed && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/character')}
                  sx={{ 
                    py: 1.5,
                    px: 3,
                    fontWeight: 'bold'
                  }}
                >
                  Повернутися до персонажа
                </Button>
              )}
              
              {activeTower.status === 'active' && !canAdvance && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<CancelIcon />}
                    onClick={handleRetireTower}
                    sx={{ 
                      py: 1.5,
                      fontWeight: 'bold'
                    }}
                  >
                    Відмовитися від проходження
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Card>
        
        <Paper sx={{ p: 4, bgcolor: 'rgba(40, 37, 35, 0.7)' }}>
          <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <AccountBalanceIcon sx={{ mr: 1 }} /> 
            Прогрес проходження башти
          </Typography>
          
          <Divider sx={{ mb: 4, mt: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />

          <Stepper activeStep={activeTower.currentFloor - 1} orientation="vertical">
            {Array.from({ length: activeTower.maxFloors }, (_, index) => {
              const floorNumber = index + 1;
              const isCurrent = floorNumber === activeTower.currentFloor;
              const isCompleted = floorNumber < activeTower.currentFloor;
              
              // Знаходимо бій для цього поверху
              const floorBattle = activeTower.battles.find(b => b.floor === floorNumber);
              
              return (
                <Step key={floorNumber} active={isCurrent} completed={isCompleted}>
                  <StepLabel
                    StepIconProps={{ 
                      icon: floorNumber,
                      color: getBattleStatusColor(floorBattle)
                    }}
                  >
                    <Typography 
                      variant="h6" 
                      color={isCurrent ? 'secondary.main' : 'inherit'}
                      fontWeight={isCurrent ? 'bold' : 'normal'}
                    >
                      Поверх {floorNumber}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="body1" paragraph>
                        {isCurrent ? (
                          <>Ви зараз на цьому поверсі. {!floorBattle?.completed ? 'Завершіть бій, щоб просуватися далі.' : 'Ви можете перейти на наступний поверх.'}</>
                        ) : (
                          isCompleted ? 'Ви вже пройшли цей поверх.' : 'Ви ще не досягли цього поверху.'
                        )}
                      </Typography>
                      
                      {floorBattle && (
                        <Card sx={{ 
                          mb: 2, 
                          bgcolor: 'rgba(0, 0, 0, 0.2)', 
                          border: '1px solid rgba(255, 255, 255, 0.1)'
                        }}>
                          <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              {getBattleStatusIcon(floorBattle)}
                              <Typography 
                                variant="subtitle1" 
                                sx={{ ml: 1, fontWeight: 'bold' }}
                                color={getBattleStatusColor(floorBattle) === 'default' ? 'inherit' : `${getBattleStatusColor(floorBattle)}.main`}
                              >
                                Бій: {getBattleStatusText(floorBattle)}
                              </Typography>
                            </Box>
                            
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              ID бою: {floorBattle.battleId}
                            </Typography>
                            
                            {floorBattle.completed && floorBattle.result === 'win' && floorNumber === activeTower.currentFloor && (
                              <Button
                                variant="outlined"
                                size="small"
                                color="secondary"
                                startIcon={<ArrowUpwardIcon />}
                                onClick={handleAdvanceToNextFloor}
                                sx={{ mt: 2 }}
                              >
                                Перейти на наступний поверх
                              </Button>
                            )}
                          </CardContent>
                        </Card>
                      )}
                      
                      {!floorBattle && isCurrent && (
                        <Typography variant="body2" color="warning.main">
                          Бій ще не розпочато. Зачекайте, будь ласка...
                        </Typography>
                      )}
                    </Box>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
          
          {activeTower.status === 'completed' && (
            <Box 
              sx={{ 
                mt: 4, 
                p: 3, 
                textAlign: 'center',
                bgcolor: 'rgba(76, 175, 80, 0.1)', 
                borderRadius: 2,
                border: '1px solid rgba(76, 175, 80, 0.3)'
              }}
            >
              <EmojiEventsIcon sx={{ fontSize: '4rem', color: '#4caf50', mb: 1 }} />
              <Typography variant="h5" color="success.main" gutterBottom fontWeight="bold">
                Вітаємо! Ви успішно пройшли башту!
              </Typography>
              <Typography variant="body1">
                Ви отримали додатковий досвід та нагороди за проходження всіх поверхів. 
                Ваш персонаж став сильнішим!
              </Typography>
            </Box>
          )}
          
          {activeTower.status === 'failed' && (
            <Box 
              sx={{ 
                mt: 4, 
                p: 3, 
                textAlign: 'center',
                bgcolor: 'rgba(244, 67, 54, 0.1)', 
                borderRadius: 2,
                border: '1px solid rgba(244, 67, 54, 0.3)'
              }}
            >
              <CancelIcon sx={{ fontSize: '4rem', color: '#f44336', mb: 1 }} />
              <Typography variant="h5" color="error.main" gutterBottom fontWeight="bold">
                На жаль, прогрес у башті втрачено!
              </Typography>
              <Typography variant="body1">
                Ви не змогли пройти випробування башти, але отримали цінний досвід. 
                Спробуйте ще раз із більш сильним персонажем або кращою стратегією!
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
      
      {/* Діалогове вікно підтвердження відмови від проходження */}
      <Dialog
        open={showRetireDialog}
        onClose={() => setShowRetireDialog(false)}
      >
        <DialogTitle>Відмова від проходження башти</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ви дійсно хочете відмовитися від проходження башти? Прогрес буде втрачено і башта буде позначена як "Провалена".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowRetireDialog(false)} color="primary">
            Скасувати
          </Button>
          <Button onClick={confirmRetire} color="error" autoFocus>
            Відмовитися
          </Button>
        </DialogActions>
      </Dialog>
    </GameTemplate>
  );
};