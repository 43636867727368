// stores/towerStore.ts
import { create } from 'zustand';
import axios from 'axios';
import { useAuthStore } from './authStore';

// Типи для башти
export interface TowerBattle {
  floor: number;
  battleId: string;
  completed: boolean;
  result?: 'win' | 'loss' | 'draw';
}

export interface Tower {
  id: string;
  userId: string;
  characterId: string;
  currentFloor: number;
  status: 'active' | 'completed' | 'failed';
  maxFloors: number;
  battles: TowerBattle[];
  createdAt: Date;
  updatedAt: Date;
}

export interface TowerState {
  activeTower: Tower | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

interface TowerStore extends TowerState {
  // Дії для управління станом башти
  fetchActiveTower: () => Promise<void>;
  fetchTowerById: (towerId: string) => Promise<Tower | null>;
  setActiveTower: (tower: Tower) => void;
  updateTower: (tower: Tower) => void;
  clearActiveTower: () => void;
}

export const useTowerStore = create<TowerStore>((set, get) => ({
  activeTower: null,
  status: 'idle',
  error: null,
  
  fetchActiveTower: async () => {
    try {
      set({ status: 'loading', error: null });
      const token = useAuthStore.getState().token;
      
      const response = await axios.get('http://138.68.177.194:4200/tower/active', {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      // API може повернути null, якщо немає активної башти
      if (response.data) {
        set({ 
          activeTower: response.data, 
          status: 'succeeded' 
        });
        return response.data;
      } else {
        set({ 
          activeTower: null, 
          status: 'succeeded' 
        });
        return null;
      }
    } catch (error) {
      set({ 
        status: 'failed', 
        error: error.response?.data?.message || 'Помилка завантаження башти' 
      });
      return null;
    }
  },
  
  fetchTowerById: async (towerId: string) => {
    try {
      set({ status: 'loading', error: null });
      const token = useAuthStore.getState().token;
      
      const response = await axios.get(`http://138.68.177.194:4200/tower/${towerId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      return response.data;
    } catch (error) {
      set({ 
        status: 'failed', 
        error: error.response?.data?.message || 'Помилка завантаження башти' 
      });
      return null;
    }
  },
  
  setActiveTower: (tower) => {
    set({ 
      activeTower: tower, 
      status: 'succeeded' 
    });
  },
  
  updateTower: (tower) => {
    const activeTower = get().activeTower;
    
    // Оновлюємо тільки якщо це та сама башта
    if (activeTower && activeTower.id === tower.id) {
      set({ 
        activeTower: tower, 
        status: 'succeeded' 
      });
    }
  },
  
  clearActiveTower: () => {
    set({ 
      activeTower: null, 
      status: 'idle' 
    });
  }
}));