// atoms/StatusBar.tsx
import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';

interface StatusBarProps {
  current: number;
  max: number;
  label?: string;
  isDark?: boolean;
  lightText?: boolean;
  height?: number;
}

export const StatusBar: React.FC<StatusBarProps> = ({ 
  current, 
  max, 
  label, 
  isDark = false,
  lightText = false,
  height = 8
}) => {
  const percent = (current / max) * 100;
  
  const getColor = (percent: number): string => {
    if (percent > 70) return 'success.main';
    if (percent > 30) return 'warning.main';
    return 'error.main';
  };
  
  const textColor = lightText ? 'white' : (isDark ? '#e8e2d5' : 'inherit');
  
  return (
    <Box sx={{ mt: 2, mb: 1 }}>
      {label && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
          <Typography variant="body2" color={textColor}>
            {label}: {current}/{max}
          </Typography>
          <Typography variant="body2" sx={{ color: getColor(percent) }}>
            {Math.round(percent)}%
          </Typography>
        </Box>
      )}
      <LinearProgress
        variant="determinate"
        value={percent}
        sx={{ 
          height: height, 
          borderRadius: Math.min(height/2, 2),
          bgcolor: isDark ? 'rgba(255, 255, 255, 0.2)' : 'grey.300',
          '& .MuiLinearProgress-bar': {
            bgcolor: getColor(percent)
          }
        }}
      />
    </Box>
  );
};