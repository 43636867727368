// atoms/CharacterStat.tsx
import React from 'react';
import { Box, Typography, SvgIconProps } from '@mui/material';

interface CharacterStatProps {
  value: number;
  label: string;
  icon: React.ReactElement<SvgIconProps>;
}

export const CharacterStat: React.FC<CharacterStatProps> = ({ value, label, icon }) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      {React.cloneElement(icon, { sx: { color: 'white', fontSize: '1.8rem' } })}
      <Typography variant="subtitle2" color="white">
        {label}
      </Typography>
      <Typography variant="h6" color="white" fontWeight="bold">
        {value}
      </Typography>
    </Box>
  );
};