// templates/AuthTemplate.tsx
import React from 'react';
import { Box, Typography, Paper, useTheme } from '@mui/material';

// Декоративні елементи (можна зберегти)
const FloralTopRight = () => (
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      width: 150,
      height: 150,
      opacity: 0.15,
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M100,0 C80,20 80,50 100,70 M90,0 C70,30 70,60 90,80 M80,0 C60,40 60,70 80,90' stroke='%23e8e2d5' stroke-width='1' fill='none'/%3E%3C/svg%3E")`,
    }}
  />
);

const FloralBottomLeft = () => (
  <Box
    sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: 150,
      height: 150,
      opacity: 0.15,
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M0,100 C20,80 50,80 70,100 M0,90 C30,70 60,70 80,90 M0,80 C40,60 70,60 90,80' stroke='%23e8e2d5' stroke-width='1' fill='none'/%3E%3C/svg%3E")`,
    }}
  />
);

interface AuthTemplateProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
}

export const AuthTemplate: React.FC<AuthTemplateProps> = ({ 
  children, 
  title = "АВРОРА", 
  subtitle = "БАШТА ВИПРОБУВАНЬ" 
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        position: 'relative',
        // Фонове зображення на весь екран 
        // (необхідно замінити на фактичний шлях після генерації зображення)
        backgroundImage: 'url("/images/background.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        // Затемнення всього фону для кращої видимості форми
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.4)', // легке затемнення всього зображення
          zIndex: 1
        }
      }}
    >
      {/* Лого або верхній заголовок */}
      <Box 
        sx={{ 
          position: 'absolute', 
          top: 40, 
          left: 0, 
          width: '100%',
          textAlign: 'center',
          zIndex: 2
        }}
      >
        <Typography 
          variant="h2" 
          sx={{ 
            color: '#e8e2d5', 
            letterSpacing: '0.2em',
            fontWeight: 500,
            textShadow: '0 2px 10px rgba(0, 0, 0, 0.7)'
          }}
        >
          {title}
        </Typography>
        <Typography 
          variant="h6" 
          sx={{ 
            color: '#c7bea9', 
            letterSpacing: '0.15em',
            textShadow: '0 2px 5px rgba(0, 0, 0, 0.7)',
            mt: 1
          }}
        >
          {subtitle}
        </Typography>
      </Box>

      {/* Контейнер для форми, розташований праворуч */}
      <Box 
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end', // Розташування вмісту праворуч
          alignItems: 'center',
          position: 'relative',
          zIndex: 2,
          p: { xs: 2, md: 4 }
        }}
      >
        {/* Текстовий блок зліва (опціонально) - видимий тільки на великих екранах */}
        <Box 
          sx={{ 
            position: 'absolute', 
            left: { xs: '50%', md: '10%' },
            top: '50%',
            transform: { xs: 'translate(-50%, -50%)', md: 'translateY(-50%)' },
            textAlign: { xs: 'center', md: 'left' },
            maxWidth: { xs: '90%', md: '40%' },
            display: { xs: 'none', md: 'block' },
            zIndex: 2
          }}
        >
          <Typography 
            variant="h3" 
            sx={{ 
              color: '#e8e2d5', 
              textShadow: '0 2px 10px rgba(0, 0, 0, 0.8)',
              mb: 2
            }}
          >
            Шлях героя 
            <br />
            починається тут
          </Typography>
          <Typography 
            variant="h6" 
            sx={{ 
              color: '#c7bea9', 
              textShadow: '0 2px 8px rgba(0, 0, 0, 0.8)',
              maxWidth: '400px'
            }}
          >
            Пройдіть випробування башти, відкрийте древні таємниці 
            та здобудьте могутність, про яку ви ніколи не мріяли.
          </Typography>
        </Box>
        
        {/* Форма праворуч */}
        <Paper
          elevation={10}
          sx={{
            width: '100%',
            maxWidth: { xs: '100%', sm: '500px' },
            backgroundColor: 'rgba(20, 20, 20, 0.75)', // темний напівпрозорий фон
            backdropFilter: 'blur(10px)', // розмиття фону
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderRadius: 2,
            p: { xs: 3, md: 5 },
            position: 'relative',
            overflow: 'hidden',
            mr: { xs: 0, md: 6, lg: 12 }, // Відступ від правого краю (збільшується на великих екранах)
            animation: 'slideInRight 0.6s ease-out',
            '@keyframes slideInRight': {
              '0%': {
                opacity: 0,
                transform: 'translateX(50px)'
              },
              '100%': {
                opacity: 1,
                transform: 'translateX(0)'
              }
            }
          }}
        >
          <FloralTopRight />
          <FloralBottomLeft />
          {children}
        </Paper>
      </Box>
    </Box>
  );
};