// organisms/CharacterInfoPanel.tsx
import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Button, 
  Grid, 
  Divider, 
  Avatar, 
  LinearProgress,
  Tab,
  Tabs
} from '@mui/material';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { Character } from '../types/character';
import { StatusBar } from '../atoms/StatusBar';
import { useCharacterStore } from '../stores/characterStore';
import { TowerButton } from '../molecules/TowerButton'; // Імпортуємо новий компонент

interface CharacterInfoPanelProps {
  character: Character;
  onStartBattle: () => void;
  disabled?: boolean;
}

export const CharacterInfoPanel: React.FC<CharacterInfoPanelProps> = ({ 
  character, 
  onStartBattle, 
  disabled = false 
}) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const { getCharacterHealthPercentage, isCharacterHealthEnough } = useCharacterStore();
  
  const characterImagePath = `/images/characters/${character.code}.png`;
  const healthPercentage = getCharacterHealthPercentage(character);
  const isHealthEnough = isCharacterHealthEnough(character);
  
  // Підрахунок загальної кількості боїв і відсотка перемог
  const totalBattles = character.wins + character.losses + character.draws;
  const winPercentage = totalBattles > 0 
    ? ((character.wins / totalBattles) * 100).toFixed(1) 
    : '0';
  
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Paper
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(40, 37, 35, 0.7)',
        borderRadius: 2,
        overflow: 'hidden'
      }}
    >
      {/* Хедер з іменем та основною інформацією */}
      <Box
        sx={{
          position: 'relative',
          height: '200px',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {/* Фонове зображення (розмите) */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${characterImagePath})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'blur(5px)',
            opacity: 0.6,
            transform: 'scale(1.1)'
          }}
        />
        
        {/* Затемнення */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        
        {/* Контент хедера */}
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            padding: 3,
            height: '100%',
            zIndex: 1
          }}
        >
          {/* Аватар персонажа (чітке зображення) */}
          <Avatar 
            src={characterImagePath}
            sx={{
              width: 140,
              height: 140,
              border: '3px solid rgba(140, 123, 115, 0.7)',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)'
            }}
          />
          
          {/* Інформація праворуч від аватара */}
          <Box sx={{ ml: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}>
            <Typography variant="h4" color="white" fontWeight="bold" sx={{ mb: 1, textShadow: '0 2px 4px rgba(0,0,0,0.9)' }}>
              {character.name}
            </Typography>
            
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body1" color="white" sx={{ mr: 2, textShadow: '0 1px 2px rgba(0,0,0,0.9)' }}>
                Рівень: {character.level}
              </Typography>
              <Typography variant="body1" color="white" sx={{ textShadow: '0 1px 2px rgba(0,0,0,0.9)' }}>
                Досвід: {character.experience}
              </Typography>
            </Box>
            
            <StatusBar 
              current={character.currentLife}
              max={character.maxLife}
              label="Здоров'я"
              isDark
              lightText
              height={12}
            />
            
            {/* Відображаємо індикатор відновлення здоров'я, якщо < 100% */}
            {healthPercentage < 100 && (
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                mt: 1,
                color: 'white',
                px: 1
              }}>
                <LocalHospitalIcon sx={{ color: '#4caf50', mr: 1, fontSize: '1rem' }} />
                <Typography variant="caption" sx={{ opacity: 0.9 }}>
                  Здоров'я відновлюється
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      
      {/* Таби для переключення різних видів інформації */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'rgba(30, 27, 25, 0.9)' }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Характеристики" />
          <Tab label="Здібності" />
          <Tab label="Статистика" />
        </Tabs>
      </Box>
      
      {/* Контент табів */}
      <Box sx={{ flexGrow: 1, overflow: 'auto', p: 3 }}>
        {/* Таб 1: Характеристики */}
        {activeTab === 0 && (
          <Box>
            <Typography variant="h6" color="white" gutterBottom>
              Опис персонажа
            </Typography>
            <Typography variant="body1" color="white" sx={{ opacity: 0.9, mb: 3 }}>
              {character.description}
            </Typography>
            
            <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.2)' }} />
            
            <Typography variant="h6" color="white" gutterBottom>
              Основні характеристики
            </Typography>
            
            <Grid container spacing={3} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <Paper sx={{ p: 2, textAlign: 'center', bgcolor: 'rgba(255, 255, 255, 0.05)' }}>
                  <FitnessCenterIcon sx={{ fontSize: '2.5rem', color: '#e8e2d5', mb: 1 }} />
                  <Typography variant="h6" color="white">
                    Сила
                  </Typography>
                  <Typography variant="h4" color="white" fontWeight="bold">
                    {character.strength}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper sx={{ p: 2, textAlign: 'center', bgcolor: 'rgba(255, 255, 255, 0.05)' }}>
                  <DirectionsRunIcon sx={{ fontSize: '2.5rem', color: '#e8e2d5', mb: 1 }} />
                  <Typography variant="h6" color="white">
                    Спритність
                  </Typography>
                  <Typography variant="h4" color="white" fontWeight="bold">
                    {character.agility}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Paper sx={{ p: 2, textAlign: 'center', bgcolor: 'rgba(255, 255, 255, 0.05)' }}>
                  <AutoAwesomeIcon sx={{ fontSize: '2.5rem', color: '#e8e2d5', mb: 1 }} />
                  <Typography variant="h6" color="white">
                    Удача
                  </Typography>
                  <Typography variant="h4" color="white" fontWeight="bold">
                    {character.luck}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            
            {/* Інформація про відновлення здоров'я */}
            {healthPercentage < 100 && (
              <Paper 
                sx={{ 
                  mt: 3,
                  p: 2, 
                  bgcolor: 'rgba(76, 175, 80, 0.1)', 
                  border: '1px solid rgba(76, 175, 80, 0.2)',
                  borderRadius: 1 
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'flex-start'
                }}>
                  <LocalHospitalIcon sx={{ 
                    color: '#4caf50', 
                    mr: 1.5,
                    fontSize: '2rem',
                    mt: 0.5
                  }} />
                  <Box>
                    <Typography variant="subtitle1" color="white" gutterBottom>
                      Відновлення здоров'я
                    </Typography>
                    <Typography variant="body2" color="white" sx={{ opacity: 0.9 }}>
                      Здоров'я вашого персонажа поступово відновлюється з часом. Для участі в бою або проходження башти необхідно мати мінімум 30% здоров'я.
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="caption" color="#4caf50" sx={{ fontWeight: 'bold' }}>
                        Поточний стан: {Math.round(healthPercentage)}% 
                        {healthPercentage < 30 
                          ? ' (недостатньо для активностей)' 
                          : ' (достатньо для активностей)'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            )}
          </Box>
        )}
        
        {/* Таб 2: Здібності */}
        {activeTab === 1 && (
          <Box>
            <Typography variant="h6" color="white" gutterBottom>
              Здібності персонажа
            </Typography>
            
            {character.abilities && character.abilities.length > 0 ? (
              character.abilities.map((ability) => (
                <Paper 
                  key={ability.code} 
                  sx={{ 
                    p: 2, 
                    mb: 2, 
                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                    borderLeft: '4px solid #8c7b73'
                  }}
                >
                  <Typography variant="h6" color="white" gutterBottom>
                    {ability.name} (Рівень {ability.level})
                  </Typography>
                  <Typography variant="body1" color="white" sx={{ opacity: 0.9, mb: 1 }}>
                    {ability.description}
                  </Typography>
                  
                  {ability.effects && ability.effects.length > 0 && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2" color="white" sx={{ mb: 1, opacity: 0.7 }}>
                        Ефекти:
                      </Typography>
                      {ability.effects.map((effect, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                          <Typography variant="body2" color="white" sx={{ mr: 1 }}>
                            • {effect.name}:
                          </Typography>
                          <Typography variant="body2" color="white" sx={{ opacity: 0.8 }}>
                            {effect.description}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Paper>
              ))
            ) : (
              <Typography variant="body1" color="white" sx={{ opacity: 0.7, textAlign: 'center', mt: 4 }}>
                У цього персонажа поки немає здібностей
              </Typography>
            )}
          </Box>
        )}
        
        {/* Таб 3: Статистика */}
        {activeTab === 2 && (
          <Box>
            <Typography variant="h6" color="white" gutterBottom>
              Бойова статистика
            </Typography>
            
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={6} sm={3}>
                <Paper sx={{ p: 2, textAlign: 'center', bgcolor: 'rgba(255, 255, 255, 0.05)' }}>
                  <Typography variant="h6" color="white">
                    Всього боїв
                  </Typography>
                  <Typography variant="h4" color="white" fontWeight="bold">
                    {totalBattles}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Paper sx={{ p: 2, textAlign: 'center', bgcolor: 'rgba(255, 255, 255, 0.05)' }}>
                  <Typography variant="h6" color="white">
                    Перемог
                  </Typography>
                  <Typography variant="h4" color="#4caf50" fontWeight="bold">
                    {character.wins}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Paper sx={{ p: 2, textAlign: 'center', bgcolor: 'rgba(255, 255, 255, 0.05)' }}>
                  <Typography variant="h6" color="white">
                    Поразок
                  </Typography>
                  <Typography variant="h4" color="#f44336" fontWeight="bold">
                    {character.losses}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Paper sx={{ p: 2, textAlign: 'center', bgcolor: 'rgba(255, 255, 255, 0.05)' }}>
                  <Typography variant="h6" color="white">
                    Нічиїх
                  </Typography>
                  <Typography variant="h4" color="#ff9800" fontWeight="bold">
                    {character.draws}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            
            <Paper sx={{ p: 3, bgcolor: 'rgba(255, 255, 255, 0.05)', mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="h6" color="white">
                  Відсоток перемог:
                </Typography>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: Number(winPercentage) > 60 ? '#4caf50' : 
                    Number(winPercentage) > 40 ? '#ff9800' : '#f44336' 
                  }}
                >
                  {winPercentage}%
                </Typography>
              </Box>
              <LinearProgress 
                variant="determinate" 
                value={Number(winPercentage)} 
                sx={{ 
                  height: 10, 
                  borderRadius: 5,
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                  '& .MuiLinearProgress-bar': {
                    bgcolor: Number(winPercentage) > 60 ? '#4caf50' : 
                    Number(winPercentage) > 40 ? '#ff9800' : '#f44336'
                  }
                }} 
              />
            </Paper>
            
            <Box sx={{ textAlign: 'center', opacity: 0.8 }}>
              <EmojiEventsIcon sx={{ fontSize: '3rem', color: '#e8e2d5', mb: 1 }} />
              <Typography variant="body1" color="white">
                Покращуйте свою статистику перемагаючи суперників у боях та башті!
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      
      {/* Футер з кнопками */}
      <Box
        sx={{
          p: 2,
          bgcolor: 'rgba(30, 27, 25, 0.9)',
          borderTop: '1px solid rgba(255, 255, 255, 0.1)'
        }}
      >
        {/* Додаємо кнопки для бою та башти у Grid для кращого розташування */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              startIcon={<SportsMmaIcon />}
              onClick={onStartBattle}
              disabled={disabled || !isHealthEnough}
              sx={{ 
                py: 1.5,
                fontSize: '1.1rem',
                fontWeight: 'bold',
                background: isHealthEnough ? 
                  'linear-gradient(135deg, #6a5c55, #4a3b39)' : 
                  'linear-gradient(135deg, rgba(244, 67, 54, 0.7), rgba(211, 47, 47, 0.7))',
                '&:hover': {
                  background: isHealthEnough ? 
                    'linear-gradient(135deg, #8c7b73, #6a5c55)' : 
                    'linear-gradient(135deg, rgba(244, 67, 54, 0.8), rgba(211, 47, 47, 0.8))',
                }
              }}
            >
              {isHealthEnough ? 'У БІЙ!' : "НЕДОСТАТНЬО ЗДОРОВ'Я (МІНІМУМ 30%)"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* Додаємо нову кнопку для проходження башти */}
            <TowerButton character={character} disabled={disabled} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};