// stores/battleStore.ts
import { create } from 'zustand';
import { BattleState, BattleDetail } from '../types/battle';

interface BattleStore extends BattleState {
  setCurrentBattle: (battle: BattleDetail) => void;
  updateBattle: (battle: BattleDetail) => void;
  joinBattleRequest: (data: { characterId: string }) => void;
  cancelJoinBattleRequest: () => void;
  cancelJoinBattleSuccess: () => void;
  moveBattleRequest: (data: { battleId: string; actions: string[] }) => void;
  finishBattle: () => void;
}

export const useBattleStore = create<BattleStore>((set, get) => ({
  currentBattle: null,
  battleSearch: false,
  inMove: false,
  status: 'idle',
  error: null,
  
  setCurrentBattle: (battle) => {
    console.log('setCurrentBattle, status:', battle.status);
    set({ 
      currentBattle: battle, 
      status: battle.status === 'finished' ? 'finished' : 'ongoing', 
      inMove: false, 
      battleSearch: false 
    });
  },
  
  updateBattle: (battle) => {
    console.log('updateBattle, status:', battle.status);
    // Оновлюємо стан тільки якщо ідентифікатор бою співпадає
    const currentBattle = get().currentBattle;
    if (currentBattle && currentBattle.id === battle.id) {
      // Якщо оновлення бою має статус "finished", зберігаємо цей статус
      const newStatus = battle.status === 'finished' ? 'finished' : 'ongoing';
      
      // Логуємо зміну статусу для відстеження проблем
      if (get().status !== newStatus) {
        console.log(`Зміна статусу бою: ${get().status} -> ${newStatus}`);
      }
      
      set({ 
        currentBattle: battle, 
        status: newStatus,
        inMove: false
      });
    }
  },
  
  joinBattleRequest: () => set({ 
    battleSearch: true 
  }),
  
  cancelJoinBattleRequest: () => {
    // Буде викликано через сокет
  },
  
  cancelJoinBattleSuccess: () => set({ 
    battleSearch: false 
  }),
  
  moveBattleRequest: () => set({ 
    inMove: true 
  }),
  
  finishBattle: () => {
    console.log('finishBattle викликано');
    set({ 
      status: 'idle', 
      currentBattle: null, 
      inMove: false, 
      battleSearch: false 
    });
  }
}));