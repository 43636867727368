// atoms/Button.tsx
import React from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';

interface ButtonProps extends MuiButtonProps {
  // Можна додати додаткові властивості
}

export const Button: React.FC<ButtonProps> = (props) => {
  return <MuiButton {...props} />;
};