// organisms/LoginForm.tsx
import React, { useState } from 'react';
import { 
  Box, 
  Alert, 
  Typography, 
  TextField, 
  Button, 
  Divider, 
  Grid, 
  InputAdornment,
  IconButton,
  Link
} from '@mui/material';
import { useAuthStore } from '../stores/authStore';
import { Link as RouterLink } from 'react-router-dom';
import { Visibility, VisibilityOff, Google, Facebook } from '@mui/icons-material';

export const LoginForm: React.FC = () => {
  const [loginInput, setLoginInput] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  
  const login = useAuthStore(state => state.login);
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      await login({ login: loginInput, password });
    } catch (err: any) {
      setError(err.response?.data?.message || 'Помилка входу');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Box>
      <Typography 
        variant="h4" 
        component="h1" 
        align="center" 
        gutterBottom
        sx={{ 
          letterSpacing: '0.08em', 
          color: '#e8e2d5', 
          fontWeight: 500,
          mb: 4,
          textShadow: '0 2px 4px rgba(0,0,0,0.3)'
        }}
      >
        Вхід
      </Typography>
      
      {error && <Alert severity="error" sx={{ mb: 3, backgroundColor: 'rgba(211, 47, 47, 0.2)' }}>{error}</Alert>}
      
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 3 }}>
          <Typography 
            variant="body2" 
            component="label" 
            htmlFor="email" 
            sx={{ display: 'block', mb: 1, color: '#e8e2d5' }}
          >
            Логін або електронна пошта
          </Typography>
          <TextField
            id="email"
            fullWidth
            value={loginInput}
            onChange={(e) => setLoginInput(e.target.value)}
            placeholder="ваш@емейл.com"
            variant="outlined"
            InputProps={{
              sx: {
                color: '#e8e2d5',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(232, 226, 213, 0.5)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(232, 226, 213, 0.2)',
                },
              }
            }}
            required
          />
        </Box>
        
        <Box sx={{ mb: 4 }}>
          <Typography 
            variant="body2" 
            component="label" 
            htmlFor="password"
            sx={{ display: 'block', mb: 1, color: '#e8e2d5' }}
          >
            Пароль
          </Typography>
          <TextField
            id="password"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="••••••••"
            variant="outlined"
            InputProps={{
              sx: {
                color: '#e8e2d5',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(232, 226, 213, 0.5)',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(232, 226, 213, 0.2)',
                },
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    edge="end"
                    sx={{ color: '#e8e2d5' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            required
          />
        </Box>
        
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={loading}
          sx={{ 
            py: 1.5, 
            background: 'linear-gradient(135deg, #6a5c55, #4a3b39)',
            fontSize: '1rem',
            fontWeight: 500,
            letterSpacing: '0.05em',
            '&:hover': {
              background: 'linear-gradient(135deg, #4a3b39, #3a3030)',
              boxShadow: '0 0 10px rgba(106, 92, 85, 0.5)',
            },
            mb: 2
          }}
        >
          УВІЙТИ
        </Button>
        
        <Box sx={{ textAlign: 'right', mb: 3 }}>
          <Link 
            component={RouterLink} 
            to="/forgot-password"
            sx={{ 
              color: '#c7bea9',
              textDecoration: 'none',
              fontSize: '0.9rem',
              transition: 'color 0.3s',
              '&:hover': {
                color: '#e8e2d5',
              } 
            }}
          >
            Забули пароль?
          </Link>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
          <Divider sx={{ flexGrow: 1, borderColor: 'rgba(232, 226, 213, 0.2)' }} />
          <Typography variant="body2" sx={{ px: 2, color: '#c7bea9' }}>АБО</Typography>
          <Divider sx={{ flexGrow: 1, borderColor: 'rgba(232, 226, 213, 0.2)' }} />
        </Box>
        
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<Google />}
              sx={{ 
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                borderColor: 'rgba(232, 226, 213, 0.2)',
                color: '#e8e2d5',
                '&:hover': {
                  backgroundColor: 'rgba(106, 92, 85, 0.3)',
                  borderColor: 'rgba(232, 226, 213, 0.3)',
                }
              }}
            >
              Google
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<Facebook />}
              sx={{ 
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                borderColor: 'rgba(232, 226, 213, 0.2)',
                color: '#e8e2d5',
                '&:hover': {
                  backgroundColor: 'rgba(106, 92, 85, 0.3)',
                  borderColor: 'rgba(232, 226, 213, 0.3)',
                }
              }}
            >
              Facebook
            </Button>
          </Grid>
        </Grid>
        
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="body2" sx={{ color: '#c7bea9' }}>
            Ще не маєте облікового запису? {' '}
            <Link 
              component={RouterLink} 
              to="/register"
              sx={{ 
                color: '#e8e2d5',
                textDecoration: 'none',
                fontWeight: 500,
                transition: 'color 0.3s',
                '&:hover': {
                  color: '#e8e2d5',
                  textDecoration: 'underline',
                } 
              }}
            >
              Зареєструватися
            </Link>
          </Typography>
        </Box>
      </form>
    </Box>
  );
};