// molecules/RoundHistoryItem.tsx
import React from 'react';
import { Box, Typography, Grid, Paper, Chip, Avatar } from '@mui/material';
import { Round } from '../types/battle';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import ShieldIcon from '@mui/icons-material/Shield';
import FlagIcon from '@mui/icons-material/Flag';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface RoundHistoryItemProps {
  round: Round;
  roundNumber: number;
  battleOpponents: { 
    userId: string; 
    character: { 
      name: string;
      code: string;
    } 
  }[];
  currentUserId: string;
}

export const RoundHistoryItem: React.FC<RoundHistoryItemProps> = ({ 
  round, 
  roundNumber, 
  battleOpponents, 
  currentUserId 
}) => {
  // Функція для форматування дії
  const formatAction = (action: string): { text: string; icon: JSX.Element; color: string } => {
    const actionMap: Record<string, { text: string; icon: JSX.Element; color: string }> = {
      'HIT_HEAD': { 
        text: 'Удар в голову', 
        icon: <SportsMmaIcon fontSize="small" />, 
        color: '#f44336' 
      },
      'HIT_BODY': { 
        text: 'Удар в тулуб', 
        icon: <SportsMmaIcon fontSize="small" />, 
        color: '#f44336' 
      },
      'HIT_LEGS': { 
        text: 'Удар по ногах', 
        icon: <SportsMmaIcon fontSize="small" />, 
        color: '#f44336' 
      },
      'BLOCK_HEAD': { 
        text: 'Блок голови', 
        icon: <ShieldIcon fontSize="small" />, 
        color: '#2196f3' 
      },
      'BLOCK_BODY': { 
        text: 'Блок тулуба', 
        icon: <ShieldIcon fontSize="small" />, 
        color: '#2196f3' 
      },
      'BLOCK_LEGS': { 
        text: 'Блок ніг', 
        icon: <ShieldIcon fontSize="small" />, 
        color: '#2196f3' 
      }
    };
    
    return actionMap[action] || { 
      text: action, 
      icon: <FlagIcon fontSize="small" />, 
      color: '#9e9e9e' 
    };
  };
  
  // Форматуємо час
  const formattedTime = new Date(round.timestamp).toLocaleTimeString([], { 
    hour: '2-digit', 
    minute: '2-digit' 
  });
  
  return (
    <Paper 
      elevation={1} 
      sx={{ 
        mb: 2, 
        borderRadius: 1,
        overflow: 'hidden',
        border: '1px solid rgba(255, 255, 255, 0.1)'
      }}
    >
      {/* Заголовок раунду */}
      <Box 
        sx={{ 
          p: 1, 
          bgcolor: 'rgba(140, 123, 115, 0.2)', 
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          Раунд {roundNumber}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {formattedTime}
        </Typography>
      </Box>
      
      {/* Дії гравців */}
      <Grid container>
        {battleOpponents.map(opponent => {
          // Додаємо перевірки на існування об'єктів
          const userActions = round.actions && round.actions[opponent.userId] 
            ? round.actions[opponent.userId] 
            : [];
            
          // Додаємо перевірку на існування damage та damage[userId]
          const userDamage = round.damage && round.damage[opponent.userId] 
            ? round.damage[opponent.userId].reduce((sum, val) => sum + val, 0) 
            : 0;
          
          // Визначаємо, чи це поточний користувач
          const isCurrentUser = opponent.userId === currentUserId;
          
          // Отримуємо шлях до зображення персонажа
          const characterImagePath = `/images/characters/${opponent.character.code}.png`;
          
          return (
            <Grid item xs={12} key={opponent.userId}>
              <Box 
                sx={{ 
                  p: 1.5, 
                  borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                  bgcolor: isCurrentUser ? 'rgba(0, 0, 255, 0.05)' : 'rgba(255, 0, 0, 0.05)',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {/* Аватар персонажа */}
                <Avatar 
                  src={characterImagePath} 
                  sx={{ 
                    width: 35, 
                    height: 35, 
                    border: '2px solid',
                    borderColor: isCurrentUser ? 'primary.light' : 'error.light'
                  }} 
                />
                
                <Box sx={{ ml: 1.5, flexGrow: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {opponent.character.name}
                    {isCurrentUser ? ' (Ви)' : ''}
                  </Typography>
                  
                  {/* Дії користувача */}
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, my: 1 }}>
                    {userActions.length > 0 ? (
                      userActions.map((action, idx) => {
                        const formattedAction = formatAction(action);
                        return (
                          <Chip
                            key={idx}
                            size="small"
                            label={formattedAction.text}
                            icon={formattedAction.icon}
                            sx={{ 
                              bgcolor: `${formattedAction.color}20`,
                              color: formattedAction.color,
                              border: `1px solid ${formattedAction.color}40`
                            }}
                          />
                        );
                      })
                    ) : (
                      <Typography variant="caption" color="text.secondary">
                        Немає дій
                      </Typography>
                    )}
                  </Box>
                </Box>
                
                {/* Урон - відображаємо праворуч */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center',
                    minWidth: '60px'
                  }}
                >
                  {userDamage !== 0 && (
                    <>
                      {userDamage > 0 ? (
                        <ArrowDownwardIcon 
                          sx={{ 
                            color: 'error.main', 
                            fontSize: '1.2rem' 
                          }} 
                        />
                      ) : (
                        <ArrowUpwardIcon 
                          sx={{ 
                            color: 'success.main', 
                            fontSize: '1.2rem' 
                          }} 
                        />
                      )}
                      <Typography 
                        variant="subtitle2" 
                        sx={{ 
                          fontWeight: 'bold',
                          color: userDamage > 0 ? 'error.main' : 'success.main'
                        }}
                      >
                        {userDamage > 0 ? `-${userDamage}` : `+${Math.abs(userDamage)}`}
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};