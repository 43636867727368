// templates/GameTemplate.tsx
import React from 'react';
import { Container, Box, AppBar, Toolbar, Typography, Button } from '@mui/material';
import { UserProfile } from '../molecules/UserProfile';
import { useAuthStore } from '../stores/authStore';

interface GameTemplateProps {
  title: string;
  children: React.ReactNode;
}

export const GameTemplate: React.FC<GameTemplateProps> = ({ title, children }) => {
  const { user, logout } = useAuthStore();
  
  if (!user) return null;
  
  return (
    <Box>
      <AppBar position="static">
        <Toolbar>

          
          <UserProfile user={user} onLogout={logout} />
        </Toolbar>
      </AppBar>
      
      <Container maxWidth="lg">
        <Box sx={{ mt: 4, mb: 6 }}>
          <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          
          {children}
        </Box>
      </Container>
    </Box>
  );
};