// App.tsx
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { RegisterPage } from './pages/RegisterPage';
import { CharacterPage } from './pages/CharacterPage';
import { BattleFightPage } from './pages/BattleFightPage';
import { TowerPage } from './pages/TowerPage'; // Імпортуємо сторінку Башти
import { useAuthStore } from './stores/authStore';
import { useBattleStore } from './stores/battleStore';
import { useTowerStore } from './stores/towerStore'; // Імпортуємо TowerStore
import { useSocket } from './hooks/useSocket';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { cottageTheme } from './theme/cottagecore-theme';

const App: React.FC = () => {
  const { isAuthenticated, user, getMe } = useAuthStore();
  const { currentBattle } = useBattleStore();
  const { activeTower, fetchActiveTower } = useTowerStore(); // Отримуємо activeTower
  
  // Використання хука для сокетів
  useSocket();
  
  useEffect(() => {
    if (isAuthenticated && !user) {
      getMe();
    }
    
    // Перевіряємо активну башту при завантаженні
    if (isAuthenticated) {
      fetchActiveTower();
    }
  }, [isAuthenticated, user, getMe, fetchActiveTower]);
  
  return (
    <ThemeProvider theme={cottageTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          {!isAuthenticated ? (
            // Маршрути для неавторизованих користувачів
            <>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </>
          ) : currentBattle ? (
            // Маршрути для користувачів з активним боєм
            <>
              <Route path="/battle" element={<BattleFightPage />} />
              <Route path="*" element={<Navigate to="/battle" replace />} />
            </>
          ): activeTower ? (
            // Маршрути для користувачів з активною баштою
            <>
              <Route path="/tower" element={<TowerPage />} />
              <Route path="*" element={<Navigate to="/tower" replace />} />
            </>
          )  : (
            // Маршрути для авторизованих користувачів без активного бою або башти
            <>
              <Route path="/character" element={<CharacterPage />} />
              <Route path="*" element={<Navigate to="/character" replace />} />
            </>
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;