// stores/authStore.ts
import { create } from 'zustand';
import axios from 'axios';
import { AuthState, User } from '../types/auth';

interface AuthStore extends AuthState {
  login: (credentials: { login: string; password: string }) => Promise<void>;
  register: (data: { login: string; email: string; password: string }) => Promise<void>;
  logout: () => void;
  getMe: () => Promise<void>;
}

export const useAuthStore = create<AuthStore>((set, get) => {
  const savedToken = localStorage.getItem('token');
  const savedUser = localStorage.getItem('user');
  
  return {
    isAuthenticated: !!savedToken,
    token: savedToken,
    user: savedUser && savedUser !== 'undefined' ? JSON.parse(savedUser) : null,
    status: 'idle',
    error: null,
    
    login: async (credentials) => {
      try {
        set({ status: 'loading', error: null });
        const response = await axios.post('http://138.68.177.194:4200/auth/login', credentials);
        const { token } = response.data;
        localStorage.setItem('token', token);
        set({ token, isAuthenticated: true, status: 'succeeded' });
        await get().getMe();
      } catch (error) {
        set({ 
          status: 'failed', 
          error: error.response?.data?.message || 'Помилка входу' 
        });
        throw error;
      }
    },
    
    register: async (data) => {
      try {
        set({ status: 'loading', error: null });
        await axios.post('http://138.68.177.194:4200/auth/register', data);
        set({ status: 'succeeded' });
      } catch (error) {
        set({ 
          status: 'failed', 
          error: error.response?.data?.message || 'Помилка реєстрації' 
        });
        throw error;
      }
    },
    
    logout: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      set({
        token: null,
        isAuthenticated: false,
        user: null,
      });
    },
    
    getMe: async () => {
      try {
        const token = get().token;
        if (!token) return;
        
        const response = await axios.get('http://138.68.177.194:4200/users/me', {
          headers: { Authorization: `Bearer ${token}` },
        });
        
        const user = response.data;
        localStorage.setItem('user', JSON.stringify(user));
        set({ user });
      } catch (error) {
        if (error.response?.status === 401) {
          get().logout();
        }
      }
    }
  };
});