// src/theme/cottagecore-theme.ts
import { createTheme } from '@mui/material';

export const cottageTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#8c7b73',
      light: '#a99a92',
      dark: '#6a5c55',
      contrastText: '#e8e2d5',
    },
    secondary: {
      main: '#3a4a3d',
      light: '#5a6a5d',
      dark: '#2a3a2d',
      contrastText: '#e8e2d5',
    },
    background: {
      default: '#1a1a1a',
      paper: '#282523',
    },
    text: {
      primary: '#e8e2d5',
      secondary: '#c7bea9',
    },
    divider: 'rgba(232, 226, 213, 0.1)',
  },
  typography: {
    fontFamily: '"Cormorant Garamond", serif',
    h1: {
      fontWeight: 500,
      letterSpacing: '0.1em',
    },
    h2: {
      fontWeight: 500,
      letterSpacing: '0.08em',
    },
    h3: {
      fontWeight: 500,
      letterSpacing: '0.06em',
    },
    h4: {
      fontWeight: 500,
      letterSpacing: '0.05em',
    },
    h5: {
      fontWeight: 500,
      letterSpacing: '0.04em',
    },
    h6: {
      fontWeight: 500,
      letterSpacing: '0.03em',
    },
    button: {
      letterSpacing: '0.05em',
      fontWeight: 500,
    },
    subtitle1: {
      color: '#c7bea9',
      letterSpacing: '0.03em',
    },
    subtitle2: {
      color: '#c7bea9',
      letterSpacing: '0.02em',
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;500;600;700&display=swap');
        
        body {
          background-color: #1a1a1a;
          color: #e8e2d5;
          background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z' fill='%238c7b73' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '10px 20px',
          position: 'relative',
          overflow: 'hidden',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '0',
            height: '1px',
            backgroundColor: '#8c7b73',
            transition: 'width 0.3s',
          },
          '&:hover::after': {
            width: '100%',
          },
        },
        contained: {
          background: 'linear-gradient(135deg, #6a5c55, #4a3b39)',
          '&:hover': {
            background: 'linear-gradient(135deg, #4a3b39, #3a3030)',
            boxShadow: '0 0 10px rgba(106, 92, 85, 0.5)',
          },
        },
        outlined: {
          borderColor: 'rgba(232, 226, 213, 0.2)',
          '&:hover': {
            borderColor: 'rgba(232, 226, 213, 0.5)',
            backgroundColor: 'rgba(106, 92, 85, 0.1)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(232, 226, 213, 0.2)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(232, 226, 213, 0.3)',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#8c7b73',
            },
            '& input': {
              color: '#e8e2d5',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#c7bea9',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(40, 37, 35, 0.7)',
          borderRadius: '10px',
          backdropFilter: 'blur(5px)',
          border: '1px solid rgba(232, 226, 213, 0.1)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(232, 226, 213, 0.1)',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#8c7b73',
          textDecoration: 'none',
          '&:hover': {
            color: '#e8e2d5',
            textDecoration: 'underline',
          },
        },
      },
    },
  },
});