
// molecules/ActionButton.tsx
import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import ShieldIcon from '@mui/icons-material/Shield';

interface ActionButtonProps extends Omit<ButtonProps, 'variant'> {
  actionType: 'hit' | 'block';
  target: 'head' | 'body' | 'legs';
  selected?: boolean;
}

export const ActionButton: React.FC<ActionButtonProps> = ({ 
  actionType, 
  target, 
  selected = false, 
  ...props 
}) => {
  const getLabel = (): string => {
    switch (target) {
      case 'head':
        return actionType === 'hit' ? 'Удар у голову' : 'Блок голови';
      case 'body':
        return actionType === 'hit' ? 'Удар у корпус' : 'Блок корпусу';
      case 'legs':
        return actionType === 'hit' ? 'Удар у ноги' : 'Блок ніг';
      default:
        return '';
    }
  };
  
  const getIcon = () => {
    return actionType === 'hit' ? <SportsMmaIcon /> : <ShieldIcon />;
  };
  
  return (
    <Button
      variant={selected ? 'contained' : 'outlined'}
      color={actionType === 'hit' ? 'error' : 'primary'}
      startIcon={getIcon()}
      sx={{ 
        height: '100%', 
        minHeight: '50px',
        textTransform: 'none',
        fontWeight: selected ? 'bold' : 'normal'
      }}
      {...props}
    >
      {getLabel()}
    </Button>
  );
};

