// organisms/CharacterSlider.tsx
import React, { useRef, useState, useEffect } from 'react';
import { Box, Paper, Typography, IconButton, styled } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Character } from '../types/character';
import { StatusBar } from '../atoms/StatusBar';

interface CharacterSliderProps {
  characters: Character[];
  selectedCharacter: Character | null;
  onSelect: (character: Character) => void;
  disabled?: boolean;
}

// Стилізований компонент для картки персонажа в слайдері
const CharacterCard = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isDisabled'
})<{ isSelected?: boolean; isDisabled?: boolean }>(
  ({ theme, isSelected, isDisabled }) => ({
    position: 'relative',
    height: '120px',
    margin: '10px 0',
    borderRadius: '8px',
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    cursor: isDisabled ? 'default' : 'pointer',
    transform: isSelected ? 'scale(1.05)' : 'scale(1)',
    opacity: isDisabled ? 0.7 : 1,
    border: isSelected 
      ? `2px solid ${theme.palette.primary.main}` 
      : '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: isSelected 
      ? '0 0 15px rgba(140, 123, 115, 0.5)' 
      : theme.shadows[3],
    '&:hover': {
      boxShadow: isDisabled ? theme.shadows[3] : theme.shadows[8],
      transform: isDisabled 
        ? (isSelected ? 'scale(1.05)' : 'scale(1)') 
        : (isSelected ? 'scale(1.05)' : 'scale(1.02)')
    }
  })
);

export const CharacterSlider: React.FC<CharacterSliderProps> = ({ 
  characters, 
  selectedCharacter, 
  onSelect, 
  disabled = false 
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [canScrollDown, setCanScrollDown] = useState(true);
  
  // Функція для прокрутки слайдера
  const scroll = (direction: 'up' | 'down') => {
    if (!containerRef.current) return;
    
    const container = containerRef.current;
    const scrollAmount = 140; // Висота картки + відступи
    
    if (direction === 'up') {
      container.scrollTop -= scrollAmount;
    } else {
      container.scrollTop += scrollAmount;
    }
  };
  
  // Функція для перевірки можливості прокрутки
  const checkScrollability = () => {
    if (!containerRef.current) return;
    
    const container = containerRef.current;
    setCanScrollUp(container.scrollTop > 0);
    setCanScrollDown(
      container.scrollTop < container.scrollHeight - container.clientHeight
    );
  };
  
  // Додаємо обробник події прокрутки
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    
    container.addEventListener('scroll', checkScrollability);
    checkScrollability();
    
    // Прокручуємо до вибраного персонажа при першому завантаженні
    if (selectedCharacter && characters.length > 0) {
      const index = characters.findIndex(char => char.id === selectedCharacter.id);
      if (index !== -1) {
        setTimeout(() => {
          if (container) {
            container.scrollTop = index * 140;
            checkScrollability();
          }
        }, 300);
      }
    }
    
    return () => {
      container.removeEventListener('scroll', checkScrollability);
    };
  }, [characters, selectedCharacter]);
  
  return (
    <Paper
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(40, 37, 35, 0.7)',
        borderRadius: 2,
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {/* Верхня кнопка прокрутки */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: 1,
          backgroundColor: 'rgba(30, 27, 25, 0.9)',
          visibility: canScrollUp ? 'visible' : 'hidden',
          zIndex: 2
        }}
      >
        <IconButton 
          onClick={() => scroll('up')}
          disabled={!canScrollUp || disabled}
          color="primary"
          size="small"
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      </Box>
      
      {/* Контейнер з персонажами */}
      <Box
        ref={containerRef}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          px: 2,
          py: 1,
          '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: 'rgba(255, 255, 255, 0.05)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(140, 123, 115, 0.5)',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: 'rgba(140, 123, 115, 0.7)'
            }
          }
        }}
      >
        {characters.map((character) => (
          <CharacterCard
            key={character.id}
            isSelected={selectedCharacter?.id === character.id}
            isDisabled={disabled}
            onClick={() => !disabled && onSelect(character)}
          >
            {/* Фонове зображення персонажа */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(/images/characters/${character.code}.png)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: 1
              }}
            />
            
            {/* Затемнення та інформація про персонажа */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'linear-gradient(to right, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 100%)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 2,
                zIndex: 2
              }}
            >
              <Typography variant="h6" color="white" sx={{ textShadow: '0 2px 4px rgba(0,0,0,0.8)' }}>
                {character.name}
              </Typography>
              <Typography variant="body2" color="white" sx={{ opacity: 0.8, textShadow: '0 2px 4px rgba(0,0,0,0.8)' }}>
                Рівень: {character.level}
              </Typography>
              <Box sx={{ mt: 1 }}>
  <Typography variant="body2" color="white">
    Здоров'я: {character.currentLife}/{character.maxLife}
  </Typography>
  <StatusBar 
    current={character.currentLife}
    max={character.maxLife} 
  />
</Box>
            </Box>
           
          </CharacterCard>
        ))}
      </Box>
      
      {/* Нижня кнопка прокрутки */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          py: 1,
          backgroundColor: 'rgba(30, 27, 25, 0.9)',
          visibility: canScrollDown ? 'visible' : 'hidden',
          zIndex: 2
        }}
      >
        <IconButton 
          onClick={() => scroll('down')}
          disabled={!canScrollDown || disabled}
          color="primary"
          size="small"
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};