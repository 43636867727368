// molecules/UserProfile.tsx
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { User } from '../types/auth';

interface UserProfileProps {
  user: User;
  onLogout: () => void;
}

export const UserProfile: React.FC<UserProfileProps> = ({ user, onLogout }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        Привіт, {user.login}
      </Typography>
      <Button color="inherit" onClick={onLogout}>
        Вийти
      </Button>
    </Box>
  );
};