// pages/CharacterPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Button, Grid } from '@mui/material';
import { GameTemplate } from '../templates/GameTemplate';
import { CharacterSlider } from '../organisms/CharacterSlider';
import { CharacterInfoPanel } from '../organisms/CharacterInfoPanel';
import { useBattleStore } from '../stores/battleStore';
import { useCharacterStore } from '../stores/characterStore';
import { useSocket } from '../hooks/useSocket';

export const CharacterPage: React.FC = () => {
  const navigate = useNavigate();
  const { battleSearch, currentBattle, joinBattleRequest } = useBattleStore();
  const { characters, selectedCharacter, fetchCharacters, selectCharacter } = useCharacterStore();
  const { joinBattle, cancelJoinBattle } = useSocket();
  
  useEffect(() => {
    fetchCharacters();
  }, [fetchCharacters]);
  
  useEffect(() => {
    if (currentBattle) {
      navigate('/battle');
    }
  }, [currentBattle, navigate]);
  
  const handleCancelSearch = () => {
    useBattleStore.getState().cancelJoinBattleRequest();
    cancelJoinBattle();
  };
  
  const handleStartBattle = () => {
    if (!selectedCharacter) return;
    
    joinBattleRequest({ characterId: selectedCharacter.id });
    joinBattle({ characterId: selectedCharacter.id });
  };
  
  if (battleSearch) {
    return (
      <GameTemplate title="Пошук бою">
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Paper elevation={3} sx={{ p: 4, maxWidth: 500, width: '100%', textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              Пошук бою...
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Будь ласка, зачекайте доки система знайде для вас суперника
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleCancelSearch}>
              Скасувати пошук
            </Button>
          </Paper>
        </Box>
      </GameTemplate>
    );
  }
  
  return (
    <GameTemplate title="Зал Героїв">
      <Box 
        sx={{ 
          display: 'flex', 
          height: 'calc(100vh - 180px)', 
          p: 2,
          gap: 3,
          flexDirection: { xs: 'column', md: 'row' }
        }}
      >
        {/* Лівий блок - Слайдер персонажів */}
        <Box 
          sx={{ 
            width: { xs: '100%', md: '320px' }, 
            height: { xs: '280px', md: '100%' }, 
            flexShrink: 0 
          }}
        >
          <CharacterSlider 
            characters={characters} 
            selectedCharacter={selectedCharacter}
            onSelect={selectCharacter}
            disabled={battleSearch}
          />
        </Box>
        
        {/* Правий блок - Інформація про персонажа */}
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          {selectedCharacter ? (
            <CharacterInfoPanel 
              character={selectedCharacter} 
              onStartBattle={handleStartBattle}
              disabled={battleSearch}
            />
          ) : (
            <Paper 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                p: 3,
                backgroundColor: 'rgba(40, 37, 35, 0.7)'
              }}
            >
              <Typography variant="h5" align="center" color="text.secondary">
                Виберіть персонажа зі списку ліворуч, щоб переглянути інформацію
              </Typography>
            </Paper>
          )}
        </Box>
      </Box>
    </GameTemplate>
  );
};