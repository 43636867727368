// organisms/BattleOpponents.tsx
import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Divider, Avatar } from '@mui/material';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { CharacterStat } from '../atoms/CharacterStat';
import { StatusBar } from '../atoms/StatusBar';
import { useBattleStore } from '../stores/battleStore';
import { useAuthStore } from '../stores/authStore';

export const BattleOpponents: React.FC = () => {
  const { currentBattle } = useBattleStore();
  const { user } = useAuthStore();
  
  if (!currentBattle || !user) return null;
  
  // Сортуємо опонентів так, щоб користувач був першим
  const sortedOpponents = [...currentBattle.opponents].sort((a, b) => {
    if (a.userId === user.id) return -1;
    if (b.userId === user.id) return 1;
    return 0;
  });
  
  return (
    <Grid container spacing={4}>
      {sortedOpponents.map((opponent) => {
        // Отримуємо шлях до зображення персонажа
        const characterImagePath = `/images/characters/${opponent.character.code}.png`;
        
        // Визначаємо, чи це поточний користувач
        const isCurrentUser = opponent.userId === user.id;
        
        return (
          <Grid item xs={12} md={6} key={opponent.userId}>
            <Card sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column',
              boxShadow: 3,
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 2,
              border: isCurrentUser ? '2px solid #8c7b73' : 'none'
            }}>
              {/* Верхня частина картки з зображенням персонажа */}
              <Box sx={{ 
                position: 'relative', 
                height: '200px', 
                overflow: 'hidden'
              }}>
                {/* Фонове зображення (розмите для ефекту) */}
                <Box sx={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  right: 0, 
                  bottom: 0,
                  backgroundImage: `url(${characterImagePath})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  filter: 'blur(5px)',
                  transform: 'scale(1.1)',
                  opacity: 0.6
                }} />
                
                {/* Затемнення для кращої видимості тексту */}
                <Box sx={{ 
                  position: 'absolute', 
                  top: 0, 
                  left: 0, 
                  right: 0, 
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }} />
                
                {/* Позиціонуємо аватар та ім'я персонажа */}
                <Box sx={{ 
                  position: 'relative',
                  display: 'flex',
                  p: 2,
                  height: '100%',
                  zIndex: 1,
                  alignItems: 'center'
                }}>
                  {/* Аватар персонажа (чітке зображення) */}
                  <Avatar 
                    src={characterImagePath}
                    sx={{
                      width: 100,
                      height: 100,
                      border: '3px solid rgba(140, 123, 115, 0.7)',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)'
                    }}
                  />
                  
                  {/* Інформація про персонажа */}
                  <Box sx={{ ml: 2 }}>
                    <Typography variant="h5" fontWeight="bold" sx={{ color: 'white', textShadow: '0 2px 4px rgba(0,0,0,0.8)' }}>
                      {opponent.character.name}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: 'white', textShadow: '0 2px 4px rgba(0,0,0,0.8)' }}>
                      Рівень {opponent.character.level}
                    </Typography>
                    {isCurrentUser && (
                      <Box sx={{ 
                        mt: 1,
                        display: 'inline-block',
                        bgcolor: 'primary.main',
                        color: 'white',
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: '0.8rem'
                      }}>
                        Ви
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              
              <CardContent sx={{ flexGrow: 1, pt: 2 }}>
                <StatusBar 
                  current={opponent.character.currentLife}
                  max={opponent.character.maxLife}
                  label="Здоров'я"
                />

                <Divider sx={{ my: 2 }} />
                
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <CharacterStat 
                      label="Сила" 
                      value={opponent.character.strength} 
                      icon={<FitnessCenterIcon />} 
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CharacterStat 
                      label="Спритність" 
                      value={opponent.character.agility} 
                      icon={<DirectionsRunIcon />} 
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CharacterStat 
                      label="Удача" 
                      value={opponent.character.luck} 
                      icon={<AutoAwesomeIcon />} 
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};