// stores/characterStore.ts
import { create } from 'zustand';
import axios from 'axios';
import { Character, CharacterState } from '../types/character';
import { useAuthStore } from './authStore';

interface CharacterStore extends CharacterState {
  fetchCharacters: () => Promise<void>;
  selectCharacter: (character: Character) => void;
  updateCharacterHealth: (updatedCharacter: Partial<Character>) => void;
  getCharacterHealthPercentage: (character: Character) => number;
  isCharacterHealthEnough: (character: Character) => boolean;
}

export const useCharacterStore = create<CharacterStore>((set, get) => {
  const savedCharacter = localStorage.getItem('selectedCharacter');
  const initialSelectedCharacter = savedCharacter ? JSON.parse(savedCharacter) : null;
  
  return {
    characters: [],
    selectedCharacter: initialSelectedCharacter,
    status: 'idle',
    error: null,
    
    fetchCharacters: async () => {
      try {
        set({ status: 'loading', error: null });
        const token = useAuthStore.getState().token;
        
        const response = await axios.get('http://138.68.177.194:4200/characters', {
          headers: { Authorization: `Bearer ${token}` },
        });
        
        set({ 
          status: 'succeeded', 
          characters: response.data.characters 
        });
      } catch (error) {
        set({ 
          status: 'failed', 
          error: error.response?.data?.message || 'Помилка завантаження персонажів' 
        });
      }
    },
    
    selectCharacter: (character) => {
      set({ selectedCharacter: character });
      localStorage.setItem('selectedCharacter', JSON.stringify(character));
    },
    
    // Новий метод для оновлення здоров'я персонажів
    updateCharacterHealth: (updatedCharacter) => {
      set((state) => {
        // Переконуємося, що у нас є ID персонажа
        if (!updatedCharacter || !updatedCharacter.id) {
          console.error('Отримано недійсні дані персонажа:', updatedCharacter);
          return state;
        }
        
        console.log(`Оновлення персонажа ${updatedCharacter.id}, здоров'я: ${updatedCharacter.currentLife}/${updatedCharacter.maxLife}`);
        
        // Оновлюємо персонажа в списку всіх персонажів
        const updatedCharacters = state.characters.map(char => 
          char.id === updatedCharacter.id 
            ? { ...char, ...updatedCharacter } 
            : char
        );
        
        // Перевіряємо, чи потрібно оновити вибраного персонажа
        let updatedSelectedCharacter = state.selectedCharacter;
        if (state.selectedCharacter && state.selectedCharacter.id === updatedCharacter.id) {
          updatedSelectedCharacter = { 
            ...state.selectedCharacter, 
            ...updatedCharacter 
          };
          
          // Оновлюємо персонажа в localStorage
          localStorage.setItem('selectedCharacter', JSON.stringify(updatedSelectedCharacter));
        }
        
        return { 
          characters: updatedCharacters,
          selectedCharacter: updatedSelectedCharacter
        };
      });
    },
    
    // Хелпер для отримання відсотка здоров'я персонажа
    getCharacterHealthPercentage: (character) => {
      if (!character || character.maxLife <= 0) return 0;
      return (character.currentLife / character.maxLife) * 100;
    },
    
    // Перевірка, чи має персонаж достатньо здоров'я для бою (мінімум 30%)
    isCharacterHealthEnough: (character) => {
      return get().getCharacterHealthPercentage(character) >= 30;
    }
  };
});