// molecules/TowerButton.tsx
import React from 'react';
import { Button, Tooltip } from '@mui/material';
import TowerIcon from '@mui/icons-material/AccountBalanceOutlined';
import { Character } from '../types/character';
import { useSocket } from '../hooks/useSocket';

interface TowerButtonProps {
  character: Character;
  disabled?: boolean;
}

export const TowerButton: React.FC<TowerButtonProps> = ({ 
  character, 
  disabled = false 
}) => {
  const { startTower } = useSocket();
  
  // Перевірка наявності достатньої кількості здоров'я (мінімум 30%)
  const healthPercentage = (character.currentLife / character.maxLife) * 100;
  const hasEnoughHealth = healthPercentage >= 30;
  
  const handleStartTower = () => {
    startTower({ characterId: character.id });
  };
  
  // Якщо здоров'я недостатньо, кнопка буде неактивною
  const buttonDisabled = disabled || !hasEnoughHealth;
  
  return (
    <Tooltip 
      title={hasEnoughHealth 
        ? "Почати проходження башти з випробуваннями" 
        : "Недостатньо здоров'я для проходження башти (мінімум 30%)"
      }
    >
      <span>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleStartTower}
          disabled={buttonDisabled}
          startIcon={<TowerIcon />}
          sx={{ 
            mt: 2,
            width: '100%',
            py: 1.5,
            fontSize: '1.1rem',
            fontWeight: 'bold',
            background: hasEnoughHealth
              ? 'linear-gradient(135deg, #3a4a3d, #2a3a2d)'
              : 'linear-gradient(135deg, rgba(244, 67, 54, 0.7), rgba(211, 47, 47, 0.7))',
            '&:hover': {
              background: hasEnoughHealth
                ? 'linear-gradient(135deg, #5a6a5d, #3a4a3d)'
                : 'linear-gradient(135deg, rgba(244, 67, 54, 0.8), rgba(211, 47, 47, 0.8))',
            }
          }}
        >
          {hasEnoughHealth ? "ПРОЙТИ БАШТУ" : "МАЛО ЗДОРОВ'Я ДЛЯ БАШТИ"}
        </Button>
      </span>
    </Tooltip>
  );
};