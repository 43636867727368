// src/pages/BattleFightPage.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Card, CardContent, CircularProgress, Paper, Divider, Grid } from '@mui/material';
import { GameTemplate } from '../templates/GameTemplate';
import { BattleOpponents } from '../organisms/BattleOpponents';
import { BattleActions } from '../organisms/BattleActions';
import { RoundHistory } from '../organisms/RoundHistory';
import { useBattleStore } from '../stores/battleStore';
import { useCharacterStore } from '../stores/characterStore';
import { useTowerStore } from '../stores/towerStore';
import { useSocket } from '../hooks/useSocket';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HomeIcon from '@mui/icons-material/Home';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

// Компонент для відображення результатів бою
const BattleResultsView = () => {
  const navigate = useNavigate();
  const { currentBattle, finishBattle } = useBattleStore();
  const { fetchCharacters } = useCharacterStore();
  const { activeTower, fetchActiveTower } = useTowerStore();
  const { advanceToNextFloor } = useSocket();
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    // Завантажуємо активну башту, якщо вона є
    if (!activeTower) {
      fetchActiveTower();
    }
  }, [fetchActiveTower, activeTower]);
  
  if (!currentBattle) return null;
  
  const winner = currentBattle.opponents.find(op => op.userId === currentBattle.winnerId);
  const isDraw = !winner && currentBattle.status === 'finished';
  
  // Визначаємо, чи був бій у контексті башти
  const isTowerBattle = currentBattle.towerContext || activeTower;
  
  // Визначаємо, чи можна перейти на наступний рівень башти
  const canAdvanceToNextFloor = isTowerBattle && 
    winner && // перемога у бою
    activeTower && 
    activeTower.status === 'active' && 
    activeTower.currentFloor < activeTower.maxFloors;
  
  // Функція повернення до вибору персонажа
  const handleReturnToCharacterSelection = async () => {
    setLoading(true);
    try {
      await fetchCharacters();
      finishBattle();
      navigate('/character');
    } catch (error) {
      console.error("Помилка при завершенні бою:", error);
    } finally {
      setLoading(false);
    }
  };
  
  // Функція переходу на наступний рівень башти
  const handleAdvanceToNextFloor = async () => {
    setLoading(true);
    try {
      await fetchCharacters();
      if (activeTower) {
        advanceToNextFloor({ towerId: activeTower.id });
      }
      finishBattle();
      navigate('/tower');
    } catch (error) {
      console.error("Помилка при переході на наступний поверх:", error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <GameTemplate title="Результати бою">
      <Card 
        sx={{ 
          maxWidth: 600, 
          mx: 'auto', 
          mb: 4, 
          boxShadow: 3,
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        {/* Декоративний елемент */}
        <Box 
          sx={{ 
            height: '8px', 
            width: '100%', 
            background: isDraw 
              ? 'linear-gradient(to right, #f44336, #ff9800)' 
              : 'linear-gradient(to right, #8c7b73, #4a3b39)'
          }} 
        />
        
        <CardContent sx={{ textAlign: 'center', py: 4 }}>
          {isDraw ? (
            <>
              <LocalFireDepartmentIcon 
                sx={{ 
                  fontSize: '4rem', 
                  color: '#ff9800', 
                  mb: 2 
                }} 
              />
              <Typography variant="h4" sx={{ color: '#ff9800', fontWeight: 'bold', mb: 2 }}>
                Нічия!
              </Typography>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                Обидва суперники зазнали поразки в цьому епічному бою.
              </Typography>
            </>
          ) : (
            <>
              <EmojiEventsIcon 
                sx={{ 
                  fontSize: '4rem', 
                  color: '#8c7b73', 
                  mb: 2 
                }} 
              />
              <Typography variant="h4" sx={{ color: '#8c7b73', fontWeight: 'bold', mb: 2 }}>
                Перемога!
              </Typography>
              <Typography variant="h6" color="primary" gutterBottom>
                {winner ? winner.character.name : 'Не визначено'}
              </Typography>
              {winner && (
                <Box sx={{ mt: 2, p: 2, bgcolor: 'rgba(140, 123, 115, 0.1)', borderRadius: 1 }}>
                  <Typography variant="body1">
                    Персонаж {winner.character.name} отримав досвід та покращив свої бойові навички!
                  </Typography>
                </Box>
              )}
            </>
          )}
        </CardContent>
      </Card>
      
      {/* Кнопки дій */}
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        {canAdvanceToNextFloor ? (
          // Відображаємо кнопки для башти, якщо це бій у контексті башти і є перемога
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Button 
                variant="contained" 
                color="secondary"
                onClick={handleAdvanceToNextFloor}
                size="large"
                disabled={loading}
                startIcon={<ArrowUpwardIcon />}
                sx={{ 
                  py: 1.5, 
                  px: 4, 
                  fontSize: '1.1rem', 
                  fontWeight: 'bold',
                  width: '100%' 
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ mr: 1 }} />
                ) : null}
                Перейти на наступний поверх
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button 
                variant="outlined" 
                color="primary"
                onClick={handleReturnToCharacterSelection}
                size="large"
                disabled={loading}
                startIcon={<HomeIcon />}
                sx={{ 
                  py: 1.5, 
                  px: 4, 
                  fontSize: '1.1rem',
                  width: '100%'
                }}
              >
                Покинути башту
              </Button>
            </Grid>
          </Grid>
        ) : (
          // Звичайна кнопка повернення до вибору персонажа
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleReturnToCharacterSelection}
            size="large"
            disabled={loading}
            sx={{ 
              py: 1.5, 
              px: 4, 
              fontSize: '1.1rem', 
              fontWeight: 'bold' 
            }}
          >
            {loading ? (
              <>
                <CircularProgress size={24} sx={{ mr: 1 }} />
                Завершення...
              </>
            ) : (
              'Повернутися до вибору персонажа'
            )}
          </Button>
        )}
      </Box>
    </GameTemplate>
  );
};

// Основний компонент сторінки бою
export const BattleFightPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentBattle, finishBattle, status } = useBattleStore();
  const { fetchCharacters } = useCharacterStore();
  
  useEffect(() => {
    console.log(`BattleFightPage: status=${status}, currentBattle?.status=${currentBattle?.status}`);
  }, [status, currentBattle?.status]);
  
  useEffect(() => {
    if (!currentBattle) {
      navigate('/character');
    }
  }, [currentBattle, navigate]);
  
  if (!currentBattle) return null;
  
  // Перевіряємо обидва статуси (з локального стану і з об'єкта бою)
  const isBattleFinished = status === 'finished' || currentBattle.status === 'finished';
  
  console.log(`Rendering BattleFightPage: isBattleFinished=${isBattleFinished}`);
  
  if (isBattleFinished) {
    return <BattleResultsView />;
  }
  
  return (
    <GameTemplate title="Бій">
      <Box sx={{ mb: 4 }}>
        <BattleOpponents />
      </Box>
      
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <Box sx={{ 
              bgcolor: 'primary.dark', 
              p: 2, 
              borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
            }}>
              <Typography variant="h6" align="center">
                Дії
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <BattleActions />
            </Box>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <Box sx={{ 
              bgcolor: 'primary.dark', 
              p: 2, 
              borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
            }}>
              <Typography variant="h6" align="center">
                Історія раундів
              </Typography>
            </Box>
            <Box sx={{ p: 2, maxHeight: '400px', overflow: 'auto' }}>
              <RoundHistory />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </GameTemplate>
  );
};