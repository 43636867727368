// Оновлення у файлі src/hooks/useSocket.ts
import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAuthStore } from '../stores/authStore';
import { useBattleStore } from '../stores/battleStore';
import { useCharacterStore } from '../stores/characterStore';
import { useTowerStore } from '../stores/towerStore';

export const useSocket = () => {
  const socketRef = useRef<Socket | null>(null);
  const { token, isAuthenticated } = useAuthStore();
  const { 
    setCurrentBattle, 
    updateBattle, 
    cancelJoinBattleSuccess 
  } = useBattleStore();
  const { updateCharacterHealth } = useCharacterStore();
  const { setActiveTower, updateTower, fetchActiveTower } = useTowerStore();
  
  const connectSocket = () => {
    if (!token) return;
    
    socketRef.current = io('http://138.68.177.194:4200', {
      auth: { token },
    });
    
    socketRef.current.on('startBattle', (data) => {
      console.log('Отримано startBattle:', data);
      setCurrentBattle(data);
    });
    
    socketRef.current.on('canceledJoinBattle', (data) => {
      console.log('Отримано cancelJoinBattle:', data);
      cancelJoinBattleSuccess();
    });
    
    socketRef.current.on('roundReady', (data) => {
      console.log('Отримано roundReady:', data);
      updateBattle(data);
    });
    
    socketRef.current.on('finishedBattle', (data) => {
      console.log('Отримано finishedBattle:', data);
      if (data.status !== 'finished') {
        data.status = 'finished';
      }
      
      // Якщо бій був у контексті башти, оновлюємо інформацію про башту
      if (data.towerContext) {
        console.log('Бій був у контексті башти, оновлюємо інформацію');
        fetchActiveTower();
      }
      
      updateBattle(data);
    });
    
    // Оновлення здоров'я персонажів
    socketRef.current.on('characterUpdate', (data) => {
      console.log('Отримано characterUpdate:', data);
      if (data.character) {
        updateCharacterHealth(data.character);
      }
    });
    
    // Події башти
    socketRef.current.on('towerStarted', (data) => {
      console.log('Отримано towerStarted:', data);
      setActiveTower(data);
    });
    
    socketRef.current.on('towerUpdated', (data) => {
      console.log('Отримано towerUpdated:', data);
      updateTower(data);
    });
    
    socketRef.current.on('towerCompleted', (data) => {
      console.log('Отримано towerCompleted:', data);
      updateTower({...data, status: 'completed'});
    });
    
    socketRef.current.on('towerFailed', (data) => {
      console.log('Отримано towerFailed:', data);
      updateTower({...data, status: 'failed'});
    });
    
    socketRef.current.on('towerError', (data) => {
      console.error('Помилка башти:', data.message);
    });
    
    console.log('Підключено до ігрового сокету з токеном', token);
  };
  
  const disconnectSocket = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
      console.log('Відключено ігровий сокет');
    }
  };
  
  useEffect(() => {
    if (isAuthenticated && token) {
      connectSocket();
    } else {
      disconnectSocket();
    }
    
    return () => {
      disconnectSocket();
    };
  }, [isAuthenticated, token]);
  
  // Існуючі методи
  const joinBattle = (data: { characterId: string }) => {
    if (socketRef.current) {
      console.log('Відправлено через сокет подію joinBattle з даними:', data);
      socketRef.current.emit('joinBattle', data);
    }
  };
  
  const cancelJoinBattle = () => {
    if (socketRef.current) {
      console.log('Відправлено через сокет подію cancelJoinBattle');
      socketRef.current.emit('cancelJoinBattle');
    }
  };
  
  const moveBattle = (data: { battleId: string; actions: string[] }) => {
    if (socketRef.current) {
      console.log('Відправлено через сокет подію moveBattle');
      socketRef.current.emit('moveBattle', data);
    }
  };
  
  // Методи для башти
  const startTower = (data: { characterId: string }) => {
    if (socketRef.current) {
      console.log('Відправлено через сокет подію startTower з даними:', data);
      socketRef.current.emit('startTower', data);
    }
  };
  
  const advanceToNextFloor = (data: { towerId: string }) => {
    if (socketRef.current) {
      console.log('Відправлено через сокет подію advanceToNextFloor з даними:', data);
      socketRef.current.emit('advanceToNextFloor', data);
    }
  };
  
  const retireTower = (data: { towerId: string }) => {
    if (socketRef.current) {
      console.log('Відправлено через сокет подію retireTower з даними:', data);
      socketRef.current.emit('retireTower', data);
    }
  };
  
  return {
    socket: socketRef.current,
    joinBattle,
    cancelJoinBattle,
    moveBattle,
    startTower,
    advanceToNextFloor,
    retireTower
  };
};